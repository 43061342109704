var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-list"},[_c('h2',{class:`card-list__title card-list__title-${_vm.page}`},[_vm._v(" "+_vm._s(_vm.page === "business" ? "К чему приводит недосыпание сотрудников?" : "Как сон влияет на качество жизни?")+" ")]),(!_vm.slider)?_c('ul',{staticClass:"card-list__list"},_vm._l((_vm.cards),function(card){return _c('li',{key:card.id,class:`card-list__item card-list__item-${
        card[_vm.page].size
      } card-list__item-${card[_vm.page].size}-${_vm.page} ${
        card[_vm.page].isOpen ? 'open' : ''
      }`},[_c('div',{staticClass:"card-list__container"},[_c('h3',{staticClass:"card-list__item-title",domProps:{"innerHTML":_vm._s(card[_vm.page].isOpen ? card[_vm.page].backTitle : card[_vm.page].title)}}),_c('p',{staticClass:"card-list__item-text",domProps:{"innerHTML":_vm._s(
            card[_vm.page].isOpen
              ? card[_vm.page].backText[card[_vm.page].partOfText]
              : card[_vm.page].text
          )}}),(card[_vm.page].isOpen && card[_vm.page].backText.length > 1)?_c('button',{staticClass:"card-list__btn",staticStyle:{"right":"30px"},on:{"click":function($event){return _vm.showMoreText(card[_vm.page].backText.length, card[_vm.page])}}},[_vm._v(" "+_vm._s(card[_vm.page].partOfText === card[_vm.page].backText.length - 1 ? "В начало" : "Показать еще")+" ")]):_vm._e(),_c('button',{staticClass:"card-list__btn",on:{"click":function($event){return _vm.toggleCard(card[_vm.page])}}},[(card[_vm.page].isOpen)?_c('svg',{staticClass:"card-list__arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"12","viewBox":"0 0 12 12","fill":"none"}},[_c('path',{attrs:{"d":"M2.875 6.75L7.0625 10.9375L6 12L0 6L6 0L7.0625 1.0625L2.875 5.25H12V6.75H2.875Z","fill":"#007AFF"}})]):_vm._e(),_vm._v(" "+_vm._s(card[_vm.page].isOpen ? "Назад" : " Показать еще")+" ")])]),_c('div',{class:`card-list__animation card-list__animation-${card.id}`},[_c('lottie',{directives:[{name:"show",rawName:"v-show",value:(!card[_vm.page].isOpen),expression:"!card[page].isOpen"}],ref:`cardAnimation${card[_vm.page].id}`,refInFor:true,attrs:{"options":{
            animationData: card[_vm.page].animation,
          },"height":0,"width":535}})],1)])}),0):_vm._e(),(_vm.slider)?_c('div',{staticClass:"card-list__slider"},[_c('agile',{attrs:{"slidesToShow":1.05,"navButtons":false,"dots":true}},_vm._l((_vm.cards),function(card){return _c('div',{key:card.id,class:`card-list__item card-list__item-${
          card[_vm.page].size
        } card-list__item-${card[_vm.page].size}-${_vm.page} ${
          card[_vm.page].isOpen ? 'open' : ''
        }`},[_c('div',{staticClass:"card-list__container"},[_c('h3',{staticClass:"card-list__item-title"},[_vm._v(" "+_vm._s(card[_vm.page].isOpen ? card[_vm.page].backTitle : card[_vm.page].title)+" ")]),_c('p',{staticClass:"card-list__item-text",domProps:{"innerHTML":_vm._s(
              card[_vm.page].isOpen
                ? card[_vm.page].backText[card[_vm.page].partOfText]
                : card[_vm.page].text
            )}}),(card[_vm.page].isOpen && card[_vm.page].backText.length > 1)?_c('button',{staticClass:"card-list__btn",staticStyle:{"right":"30px"},on:{"click":function($event){return _vm.showMoreText(card[_vm.page].backText.length, card[_vm.page])}}},[_vm._v(" "+_vm._s(card[_vm.page].partOfText === card[_vm.page].backText.length - 1 ? "В начало" : "Показать еще")+" ")]):_vm._e(),_c('button',{staticClass:"card-list__btn",on:{"click":function($event){return _vm.toggleCard(card[_vm.page])}}},[(card[_vm.page].isOpen)?_c('svg',{staticClass:"card-list__arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"12","viewBox":"0 0 12 12","fill":"none"}},[_c('path',{attrs:{"d":"M2.875 6.75L7.0625 10.9375L6 12L0 6L6 0L7.0625 1.0625L2.875 5.25H12V6.75H2.875Z","fill":"#007AFF"}})]):_vm._e(),_vm._v(" "+_vm._s(card[_vm.page].isOpen ? "Назад" : " Показать еще")+" ")])]),_c('div',{class:`card-list__animation card-list__animation-${card.id}`},[_c('lottie',{directives:[{name:"show",rawName:"v-show",value:(!card[_vm.page].isOpen),expression:"!card[page].isOpen"}],attrs:{"options":{
              animationData: card[_vm.page].animation,
            },"height":0,"width":535}})],1)])}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }